/*////////////////////////////////////////////////////////////////////////////////
// App.css
// Styles the App.js components
////////////////////////////////////////////////////////////////////////////////*/

/*////////////////////////////////////////////////////////////////////////////////
// Fonts
////////////////////////////////////////////////////////////////////////////////*/

@font-face {
  src: url(../fonts/Montserrat-ExtraLight.ttf);
  font-family: Montserrat-ExtraLight;
}

@font-face {
  src: url(../fonts/Montserrat-Bold.ttf);
  font-family: Montserrat-Bold;
}

/*////////////////////////////////////////////////////////////////////////////////
// Main Styles
////////////////////////////////////////////////////////////////////////////////*/

.FlexBox {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.Main {
  min-height: 100vh;
  font-size: calc(30px + 2vmin);
  background-color: #F5F5F5;
  color: #252525;
}

.Button {
  background-color: #252525;
  border-color: #252525;
  color: #ffffff;
  font-family: "Montserrat-Bold";
  text-align: center;
  border-radius: 7px;
}

.Button:focus {
  background-color: rgb(87, 87, 87);
  outline: none !important;
}

.Title {
  font-family: "Montserrat-Bold";
  text-align: center;
  color: #252525;
}

.Text {
  font-family: "Montserrat-ExtraLight";
  text-align: center;
  color: #252525;
}

/*////////////////////////////////////////////////////////////////////////////////
// App.js styles
////////////////////////////////////////////////////////////////////////////////*/

.Sub_main {
  min-width: 70vmin;
  background-color: rgb(250, 250, 250);
}

.Name {
  margin-bottom: 0;
}

.Profile {
  min-width: 150px;
  width: 70vmin;
}

.Typewriter {
  font-family: "Montserrat-ExtraLight";
  margin-bottom: 4vmin;
  margin-top: 1vmin;
  font-size: calc(3vmin);
  color: #252525;
}

.Div_margin {
  margin: -1vmin;
}

.Main_button {
  width: calc(70px + 10vmin);
  height: calc(30px + 2vmin);
  font-size: calc(10px + 0.5vmin);
}

/*////////////////////////////////////////////////////////////////////////////////
// Resume.js styles
////////////////////////////////////////////////////////////////////////////////*/

.Paragraph {
  font-size: calc(10px + 1vmin);
  margin-bottom: 0;
}

.Info {
  font-size: calc(8px + 1vmin);
  margin-top: 0;
}

/*////////////////////////////////////////////////////////////////////////////////
// Project.js styles
////////////////////////////////////////////////////////////////////////////////*/

.Banner {
  width: 100vw;
  height: 20vh;
  background-color: #ffffff;
}

.Sub_banner {
  width: 85vmin;
  height: 20vh;
  background-color: #ffffff;
  font-family: "Montserrat-Bold";
}

.Header {
  display: flex;
  font-size: calc(5px + 5.5vmin);
  margin-bottom: 1vh;
}

.Header_without {
  display: flex;
  font-size: calc(5px + 5.5vmin);
  margin-bottom: 10px;
}

.Sub_Header {
  font-size: calc(5px + 4.5vmin);
  margin-bottom: 10px;
  width: 45vw;
}

.Main_slot {
  height: 81vh;
  width: 100vw;
}

.Sub_slot {
  width: 95vw;
  height: 70vh;
  flex-direction: row;
  background-color: #ffffff;
}

.Left_sub_slot {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  width: 30vw;
  height: 27vh;
}

.Right_sub_slot {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  width: 50vw;
  height: 30vh;
}

.Column {
  flex-direction: column;
}

.Button_styling {
  width: calc(45px + 12vmin);
  height: calc(15px + 4vmin);
  font-size: calc(5px + 1vmin);
  margin-left: 1vmin;
  margin-right: 1vmin;
}

.Image {
  height: 30vmin;
}

.Image2 {
  height: 30vmin;
}

.Image3 {
  height: 22vmin;
}

.Component_text {
  font-size: calc(5px + 2vmin);
  width: 80vw;
}

.Paragraph_container {
  width: 40vw;
}

.Wide {
  width: 90vw;
}

/*////////////////////////////////////////////////////////////////////////////////
// Notfound.js styles
////////////////////////////////////////////////////////////////////////////////*/

.Error {
  font-size: calc(20px + 2vmin);
}

/*////////////////////////////////////////////////////////////////////////////////
// Animations and Media
////////////////////////////////////////////////////////////////////////////////*/

@media only screen and (max-aspect-ratio: 3/4) { 
  .Sub_slot {
    width: 90vw;
    height: 70vh;
    flex-direction: column;
    background-color: #ffffff;
  }
  .Paragraph_container {
    width: 65vw;
  }
  .Image {
    height: 35vmin;
  }
  
  .Image2 {
    height: 35vmin;
  }
  
  .Image3 {
    height: 30vmin;
  }
  .Sub_Header {
    font-size: calc(5px + 4.5vmin);
    margin-bottom: 10px;
    width: 60vw;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .Main {animation: FadeIn2 1s;}
  .Main_delay {animation: FadeIn 1.5s;}
  .Button:hover {animation: HoverEffect 0.5s forwards;}
  .Header {animation: SlideIn 1.5s;}
  .Component_text {animation: SlideInDelay 3s;}
}

@keyframes FadeIn {
  from {opacity: 0;}   
  50% {opacity: 0;}   
  to {opacity: 1;}
}

@keyframes FadeIn2 {
  from {opacity: 0;}   
  to {opacity: 1;}
}

@keyframes HoverEffect {
  from {transform: scale(1);}
  to {transform: scale(1.01);}
}

@keyframes SlideIn {
  from {opacity: 0;}
  50% {opacity: 0; transform: translateY(20px)}
  to {transform: translateY(0px);}
}

@keyframes SlideInDelay {
  from {opacity: 0;}
  50% {opacity: 0; transform: translateY(30px)}
  to {transform: translateY(0px);}
}

@keyframes SlideInDelayExtra {
  from {opacity: 0;}
  60% {opacity: 0; transform: translateY(30px)}
  to {transform: translateY(0px);}
}